import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import config from "src/config";
import type { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { useProfileStore } from "src/store/profile";

const defaultLibraries: Libraries = ["places", "geometry"];
const defaultAdminLibraries: Libraries = ["places", "geometry", "visualization", "drawing"];

export const useGoogleMapLoader = () => {
  const { isSuperuser } = useProfileStore();
  const libraries = React.useMemo(() => {
    if (isSuperuser) {
      return defaultAdminLibraries;
    }
    return defaultLibraries;
  }, [isSuperuser]);

  return useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GOOGLE_API_KEY,
    libraries,
  });
};
