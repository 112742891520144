/** @jsxImportSource @emotion/react */
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "src/store/account";
import Navbar from "react-bootstrap/esm/Navbar";
import { useThemedCss } from "src/hooks/styles";
import { ProfileDropdownMenu, SideMenu } from "src/components/ui/menu";
import { useTitleStore } from "src/store/title";
import styles, { IStyles } from "src/pages/Layout.styles";
import { setExternalNavigate } from "src/utils/navigate";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import { useProfileStoreWithRefetchAndRequest } from "src/hooks/profile";
import { useStoreReferralFromQuery } from "src/hooks/referral";
import TrialCountdown from "src/components/trial-countdown";
import { useToastStore } from "src/store/toast";
import { useMenuStore } from "src/store/menu";
import { useTutorialStore } from "src/store/tutorial";
import PageTutorial from "src/components/tutorial";

const Layout: React.FC = () => {
  useStoreReferralFromQuery();
  const { isAuthenticated, isSuperuser } = useAuthStore();
  const isMobile = useBreakpointIsLowerOrEqual("sm");
  const location = useLocation();
  const navigate = useNavigate();
  const [{ id: profileId, activeMembership, isNewAccount, investorProfile, phoneNumber }, { isSuccess: isProfileSuccess }] =
    useProfileStoreWithRefetchAndRequest();
  const { title } = useTitleStore();
  const { addToastWarning } = useToastStore();

  const { setReadyComponents } = useTutorialStore();

  React.useEffect(() => {
    setReadyComponents(["#layout-navbar", "#layout-main"]);
  }, []);

  const { isOpen: isMenuOpen } = useMenuStore();

  const layoutEmpty = React.useMemo(() => location.pathname.startsWith("/login") || location.pathname.startsWith("/sign-up"), [location]);

  const themedCss = useThemedCss<IStyles>(styles);

  React.useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated && isProfileSuccess && profileId !== 0 && investorProfile && isNewAccount) {
        navigate("/membership/select/");
      }
    });
  }, [profileId, investorProfile, activeMembership, isAuthenticated, isProfileSuccess]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isProfileSuccess && profileId !== 0 && investorProfile === null && isAuthenticated) {
        navigate("/login/create-investor-profile/");
      }
    });
  }, [profileId, investorProfile, isAuthenticated, isProfileSuccess]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isProfileSuccess && profileId !== 0 && investorProfile !== null && isAuthenticated && !isNewAccount && !phoneNumber) {
        addToastWarning({
          title: "Please add your phone number",
          body: "We collect your phone number to improve your experience with Airvana and keep your account secure.",
        });
        navigate("/settings/", {
          state: {
            highlightPhoneNumber: true,
          },
        });
      }
    });
  }, [isProfileSuccess, profileId, investorProfile, isAuthenticated, isNewAccount, phoneNumber]);

  React.useEffect(() => setExternalNavigate(navigate), [navigate]);

  const scrollableCss = React.useMemo(() => {
    if (!layoutEmpty) {
      return [themedCss.scrollable, themedCss.scrollableNoSidebar];
    }
    return themedCss.scrollable;
  }, [layoutEmpty]);

  const navbarCss = React.useMemo(() => {
    if (!layoutEmpty) {
      return [themedCss.navbar, themedCss.navbarNoSidebar];
    }
    return themedCss.navbar;
  }, [layoutEmpty]);

  const mainWrapperCss = React.useMemo(() => {
    if (isMenuOpen) {
      return [themedCss.content, themedCss.contentOpen];
    }
    return themedCss.content;
  }, [isMenuOpen]);

  const mainWrapperSTRDatabaseCss = React.useMemo(() => {
    if (isMenuOpen) {
      return themedCss.content;
    }
    return themedCss.content;
  }, [isMenuOpen]);

  const { pathname } = useLocation();

  if (pathname.includes("/str-database") && isSuperuser) {
    return (
      <div css={isMenuOpen ? [themedCss.wrapper, themedCss.wrapperOpen] : themedCss.wrapper}>
        <SideMenu css={themedCss.sideMenu} mobileMenuContent={<TrialCountdown className="mb-2" />} />
        <div css={themedCss.layout}>
          <Navbar css={navbarCss} id="layout-navbar">
            {!isMobile ? <h1 css={themedCss.title}>{title}</h1> : <div />}
            <div css={themedCss.navbarRight}>
              <div css={themedCss.portal} id="layout-navbar-portal" />
              {!isMobile && <TrialCountdown />}
              <div className="ps-3" />
              <ProfileDropdownMenu />
            </div>
          </Navbar>
          <div id="layout-main-wrapper" css={mainWrapperSTRDatabaseCss} className="">
            <main id="layout-main" className="h-full w-full">
              <Outlet />
            </main>
          </div>
        </div>
        <PageTutorial />
      </div>
    );
  }

  return (
    <div css={isMenuOpen ? [themedCss.wrapper, themedCss.wrapperOpen] : themedCss.wrapper}>
      <SideMenu css={themedCss.sideMenu} mobileMenuContent={<TrialCountdown className="mb-2" />} />
      <div css={themedCss.layout}>
        <Navbar css={navbarCss} id="layout-navbar">
          {!isMobile ? <h1 css={themedCss.title}>{title}</h1> : <div />}
          <div css={themedCss.navbarRight}>
            <div css={themedCss.portal} id="layout-navbar-portal" />
            {!isMobile && <TrialCountdown />}
            <div className="ps-3" />
            <ProfileDropdownMenu />
          </div>
        </Navbar>
        <div id="layout-main-wrapper" css={mainWrapperCss}>
          <main id="layout-main" css={scrollableCss}>
            <Outlet />
          </main>
        </div>
      </div>
      <PageTutorial />
    </div>
  );
};

export default Layout;
