/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useAuthStore } from "src/store/account";
import PagePreloader from "src/components/loaders";
import Modal from "react-bootstrap/esm/Modal";
import { useBooleanState } from "src/hooks/boolean";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "src/components/ui/form/button";
import { Link, Navigate } from "react-router-dom";
import Text from "src/components/ui/text";
import { useProfileHasAnyPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";
import Divider from "src/components/ui/divider";
import BookCall from "src/components/book-call";

interface IPlanWrapper {
  children: JSX.Element;
  permission: MembershipPermission | MembershipPermission[];
  previousComponent?: JSX.Element;
}

const PlanWrapper: React.FC<IPlanWrapper> = ({ children, permission, previousComponent }) => {
  const { isAuthenticated, isSuperuser } = useAuthStore();
  const { t } = useTranslation();
  const { state: isModalShow, setTrue: showModal, setFalse: closeModal } = useBooleanState(false);
  const canAccessFeature = useProfileHasAnyPermission(permission);
/* 
  if (!isSuperuser) {
    return <React.Suspense fallback={<PagePreloader />}>{previousComponent}</React.Suspense>;
  }
 */

  if (!canAccessFeature) {
    return <React.Suspense fallback={<PagePreloader />}>{previousComponent}</React.Suspense>;
  }

  return (
    <React.Suspense fallback={<PagePreloader />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default PlanWrapper;
